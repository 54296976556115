.itinerary-card {
    min-height: 100%;
    &__img {
        position: relative;  
        &::after {
            background-color: rgba(0,0,0,0.3);
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            border: 0;
            top: 0;
            right: 0;
        } 
        img {
            max-height: 240px;
            width: 100%;
            height: 240px;
            object-fit: cover;     
        }
        .card-placeholder {
            height: 200px;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            background-position: bottom center;
        }
    }
    .tripDay {
        position: absolute;
        left: calc(100% - 62%);
        top: calc(100% - 70%);
        color: #fff;
        z-index: 9;
        h5 {
            margin-bottom: 0;
        }
    }
    .card-title {
        max-width: 135px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 22px;
        margin-bottom: 7px;
        font-weight: 600;
        color: #344054;
    }
    .card-text {
        color: #344054;
    }
    .user-icon {
        display: flex;
        .avatar-img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-left: -10px;
        }
    }
}
.tr-slider-wrapper {
  .tr-slider-title {
    color: rgb(52, 64, 84);
    font-family: var(--secondary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.96px;
  }
}

.tr-overview-title {
  color: #344054;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: block;
  text-align: left;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.32px;
    margin-bottom: 24px;
  }
}

.tr-overview-desc {
  color: #475467;
  font-family: var(--secondary-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: block;
  text-align: left;

  @media (max-width: 767px) {
    border-top: 1px solid #EAECF0;
    padding-top: 40px;
  }
}

.tr-btn-more {
  display: none;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 90px;
  border: 2px solid #EAECF0;
  background-color: transparent;
  color: #23262F;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: auto;
  margin: 48px 0 0;

  @media (max-width: 767px) {
    display: flex;
  }
}

.tr-overview-cards-wrap {
  padding: 50px 0;

  @media (max-width: 767px) {
    padding: 0;
    margin: 64px 0 0;
  }

  .show-more {
    color: #155EEF;
    font-weight: 500;
    cursor: pointer;
  }

  .tr-overview-single-card {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: 8px;
    background: #F9FAFB;
    transition: .3s;
    min-height: 100%;
    max-height: 380px;

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-bottom: 24px;
    }

    .tr-count {
      display: flex;
      width: 48px;
      height: 48px;
      padding: 12px 0;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: #155EEF;
      color: #fff;
      font-size: 28px;
      font-weight: 700;
      font-family: var(--primary-font);
    }

    .tr-card-title {
      color: #344054;
      font-family: var(--secondary-font);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      display: block;
      margin: 30px 0 8px;
      text-transform: capitalize;

      @media (max-width: 767px) {
        margin-top: 48px;
      }
    }

    .tr-card-desc {
      color: var(--gray-600, #475467);
      font-family: var(--secondary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      height: auto;
      overflow-y: auto;
      max-height: 150px;
      padding-right: 5px;
      text-align: left;
    }

    &:hover {
      transition: .3s;
      background: #155EEF;

      .tr-count {
        color: #155EEF;
        background: #fff;
      }

      .tr-card-title {
        color: #fff;
      }

      .tr-card-desc {
        color: #fff;
      }

      .show-more {
        color: #fff;
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }
}

.tr-single-itinerary-box {
  .itinerary-hero-img {
    width: 100%;
  }

  .tr-iti-img-wrap {
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    .tr-iti-day {
      border-radius: 6.529px;
      background: var(--primary-25, #f5f8ff);
      color: var(--base-black, #000);
      font-family: var(--secondary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19.587px;
      text-transform: uppercase;
      width: auto;
      display: inline-flex;
      height: 52px;
      justify-content: center;
      align-items: center;
      padding: 0px 13px;
      position: absolute;
      left: 15px;
      top: 15px;
    }
  }

  .tr-iti-content-wrap {
    padding: 30px 0;
    text-align: left;

    @media (max-width: 767px) {
      padding: 24px;
    }

    .tr-iti-title {
      color: #344054;
      font-family: var(--secondary-font);
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.96px;
      display: block;

      @media (max-width: 767px) {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0;
        display: block;
      }
    }

    .tr-iti-desc {
      display: block;
      margin: 10px 0 0px;
      color: #344054;
      font-family: var(--secondary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      max-width: 70%;
      width: 100%;

      @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
        width: 100%;
      }

      @media (max-width: 767px) {
        margin: 16px 0 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        max-width: 100%;
        width: 100%;
      }
    }

    .CTA-btn {
      max-width: 256px;
      width: 100%;
      border-color: #155EEF;
      padding: 5px 12px;
      height: 36px;
      line-height: 1;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      border-radius: 8px;

      @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.tr-card-grp {
  .tr-card-grp-item {
    margin-bottom: 50px;

    .tr-card-item {
      width: 100%;
      color: var(--neutrals-2, #23262f);
      font-family: var(--secondary-font);
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 40px;

      @media (max-width: 767px) {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 1;
      }
    }

    .tr-user-desc {
      display: block;
      margin: 30px 0 0px;
      color: #777E90;
      font-family: var(--secondary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      width: 100%;

      @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
        width: 100%;
      }

      @media (max-width: 767px) {
        margin: 16px 0 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        max-width: 100%;
        width: 100%;
      }
    }

    ul {
      padding-left: 25px;

      li {
        position: relative;
        margin: 0 0 15px !important;

        &::after {
          content: "";
          background-color: #777E90;
          height: 12px;
          width: 12px;
          position: absolute;
          left: -23px;
          border-radius: 50%;
          top: 7px;
        }
      }
    }
  }
}


.tr-slider-wrapper {
  margin-top: 50px;
  padding: 0;

  @media (max-width: 767px) {
    margin-top: 0;

    .tr-slider-info-area {
      display: flex;
      gap: 12px;
    }
  }

  .tr-slider-back-mob {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .tr-slider-title {
    color: #344054;
    font-family: var(--secondary-font);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.96px;

    @media (max-width: 767px) {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.64px;
    }
  }

  .tr-itinerary-slider-box {
    margin: 50px 10px 120px;

    /* @media (min-width: 1440px) {
            margin-left: calc(100% - 93.5%);
          } */
    @media (min-width: 1367px) and (max-width: 1439px) {
      margin-left: calc(100% - 96%);
    }

    @media (max-width: 767px) {
      margin: 32px 0 80px;
    }

    .slick-prev,
    .slick-next {
      top: auto;
      bottom: -113px;
      width: 48px;
      height: 48px;

      @media (max-width: 767px) {
        display: none !important;
      }
    }

    .slick-prev {
      left: auto;
      right: 100px;
    }

    .slick-next {
      right: 35px;
    }

    .slick-list {
      height: 640px;

      @media (min-width: 768px) and (max-width: 1024px) {
        height: 420px;
      }

      @media (max-width: 767px) {
        height: 405px;
      }

      .slick-slide {
        .slider-img-box {
          margin-right: 25px;

          @media (max-width: 767px) {
            display: none;
          }

          img {
            width: 100%;
            height: 650px;
            object-fit: cover;
          }
        }

        .slider-img-box.slider-img-box--mob {
          display: none;

          @media (max-width: 767px) {
            display: block;
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .slick-dots {
      text-align: left;
      bottom: -75px;
      width: auto;

      @media (max-width: 767px) {
        display: none !important;
      }

      li {
        width: 8px;
        height: 8px;
        margin: 0 4px;
        vertical-align: middle;

        &:first-child {
          margin-left: 0;
        }

        button {
          width: 8px;
          height: 8px;

          &:before {
            width: 8px;
            height: 8px;
            line-height: 1;
            opacity: 1;
            color: #b2ccff;
          }
        }
      }

      li.slick-active {
        button {
          &:before {
            opacity: 1;
            color: #155EEF;
          }
        }
      }
    }
  }
}

.tr-itinerary-inprogress-wrap {
  .tr-itinerary-inprogress-card {
    border-radius: 8px;
    border: 1px solid var(--gray-200, #eaecf0);
    background: #F9FAFB;
    padding: 32px;
    position: relative;
    margin-bottom: 24px;
    min-height: 100%;

    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 16px;
    }

    .tr-label {
      display: inline-flex;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      text-align: center;
      font-family: var(--secondary-font);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      position: absolute;
      right: 32px;
      top: auto;

      @media (min-width: 768px) and (max-width: 1024px) {
        right: 16px;
      }

      @media (max-width: 767px) {
        right: 16px;
      }
    }

    .tr-label-sent {
      background: var(--primary-50, #f9f5ff);
      color: var(--primary-700, #6941c6);
    }

    .tr-label-finished {
      background: var(--success-200, #abefc6);
      color: var(--success-600, #079455);
    }

    .tr-user-info-area {
      display: flex;
      gap: 17px;

      .tr-user-img {
        width: 64px;
        height: 64px;
        border-radius: 4px;
        // background: url(${UserProfile});
        border: 2px solid rgb(13, 110, 253, 0.41);

        img {
          width: 64px;
          height: 64px;
          object-fit: cover;
          border-radius: 6px;
          border: 2px solid #0061ea85;
        }
      }

      .tr-user-info {
        text-align: left;

        .tr-username {
          display: block;
          color: var(--gray-900, #101828);
          font-family: var(--secondary-font);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }

        .tr-user-location {
          color: var(--gray-900, #101828);
          font-family: var(--secondary-font);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
      }
    }

    .tr-user-desc {
      color: var(--gray-900, #101828);
      font-family: var(--secondary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 16px;
      text-align: justify;
    }

    @media (max-width: 767px) {
      padding: 16px;
      margin-bottom: 48px;
    }
  }
}
// trip advisor css 
.trip-advisor-paid-grp {
  display: flex;
  justify-content: start;
  margin: 50px 0 0;
  @media (max-width:767px) {
    flex-direction: column;
  }
  .trip-advisor-item {
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: #F9FAFB;
    padding:24px;
    margin-right: 24px;
    max-width: 32%;
    width: 100%;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width:767px) {
      max-width:100%;
      margin: 0 0 10px;
    }
    h3 {
      color: #344054;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        text-decoration-line: underline;
        margin-bottom: 0;
        cursor: pointer;
    }
    img {
      width:30px;
    }
  }
  .trip_advisor_unpaid {
    p {
      color: #98A2B3 !important;
      cursor: no-drop;
    }
    img {
      filter:grayscale(1);
    }
  }
}